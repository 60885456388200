.shards-landing-page--1 .welcome {
  position: relative;
  height: 100vh;
  min-height: 700px;
  background: url("../images/agency-landing/welcome-cover.jpg") no-repeat center center fixed;
  background-size: cover;
}

.shards-landing-page--1 .welcome:before {
  position: absolute;
  z-index: 0;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .8;
  background: #007bff;
}

@media (max-width: 767.98px) {
  .shards-landing-page--1 .welcome .inner-wrapper {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .shards-landing-page--1 .welcome .welcome-heading {
    font-size: 2.415rem;
  }
}

.shards-landing-page--1 .section-title {
  position: relative;
}

.shards-landing-page--1 .section-title:after {
  content: '';
  width: 30px;
  height: 2px;
  background: #007bff;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: -20px;
}

.shards-landing-page--1 .feature .icon {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(33, 37, 41, 0.1), 0 7px 14px rgba(33, 37, 41, 0.1);
  font-size: 27px;
}

.shards-landing-page--1 .section {
  border-bottom: 1px solid #eaebed;
}

.shards-landing-page--1 .section-invert {
  background: #f9fafc;
}

.shards-landing-page--1 .testimonials .avatar {
  max-width: 80px;
  overflow: hidden;
}

.shards-app-promo-page--1 .welcome {
  position: relative;
  height: 100vh;
  min-height: 700px;
  background: url("../images/app-promo/welcome-cover.jpg") no-repeat center center fixed;
  background-size: cover;
}

.shards-app-promo-page--1 .welcome:before {
  position: absolute;
  z-index: 0;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .9;
  background: #212529;
}

@media (max-width: 991.98px) {
  .shards-app-promo-page--1 .welcome {
    height: auto;
  }
  .shards-app-promo-page--1 .welcome .header-social-icons {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .shards-app-promo-page--1 .welcome {
    height: auto;
  }
  .shards-app-promo-page--1 .welcome .inner-wrapper {
    padding-top: 4.5rem;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .shards-app-promo-page--1 .welcome .welcome-heading {
    font-size: 2.415rem;
  }
}

.shards-app-promo-page--1 .welcome .iphone-mockup {
  max-width: 85%;
}

.shards-app-promo-page--1 .section-title {
  position: relative;
}

.shards-app-promo-page--1 .section-title:after {
  content: '';
  width: 30px;
  height: 2px;
  background: #17c671;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: -20px;
}

.shards-app-promo-page--1 .section-title.underline--left:after {
  left: 0;
  margin-left: 0;
}

.shards-app-promo-page--1 .app-screenshot {
  background: url("../images/app-promo/features-background.jpg") no-repeat center center fixed;
  background-size: cover;
}

.shards-app-promo-page--1 .app-screenshot:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e9ecef;
  opacity: .6;
}

.shards-app-promo-page--1 .app-screenshot img {
  max-width: 300px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  box-shadow: 0 13px 25px rgba(0, 0, 0, 0.05), 0 60px 100px rgba(192, 192, 192, 0.5);
}

@media (max-width: 991.98px) {
  .shards-app-promo-page--1 .app-screenshot img {
    display: table;
    position: static;
    transform: translate(0);
    margin: 0 auto;
  }
}

.shards-app-promo-page--1 .feature .icon {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(33, 37, 41, 0.1), 0 7px 14px rgba(33, 37, 41, 0.1);
  font-size: 27px;
}

@media (max-width: 767.98px) {
  .shards-app-promo-page--1 .feature .icon {
    margin-right: 1.5rem !important;
  }
}

.shards-app-promo-page--1 .blog .card-img-top {
  height: 100%;
}

.shards-app-promo-page--1 .section {
  border-bottom: 1px solid #eaebed;
}

.shards-app-promo-page--1 .section-invert {
  background: #f9fafc;
}

.shards-app-promo-page--1 .testimonials .avatar {
  max-width: 80px;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .shards-app-promo-page--1 .subscribe input, .shards-app-promo-page--1 .subscribe button {
    width: 100%;
  }
}

/*# sourceMappingURL=shards-extras.css.map */